import { BackendResponse, backendSend } from "@/hooks/useBackend";
import { CreateModal } from "./DynamicModal";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import { useEffect, useState } from "react";

interface BuildCheckProps {
    localBuild: string;
}

function BuildBar(props: any) {
    return <div className="text-[10px] w-full flex flex-row justify-end p-1 absolute right-0 top-0 z-50">{props.children}</div>
}

function OnModalConfirmed() {

}

export default function BuildCheck(props: BuildCheckProps) {
    const [ response, setResponse ] = useState<BackendResponse|null>(null);
    

    useEffect(() => {
        backendSend(
            {
                endpoint: "/../_build.txt",
                method: 'GET',
                isJson: false,
                responseCallback: setResponse
            })
    }, [window.location.href]);

    if (response && response.success && response.data.trim() !== props.localBuild) {
        // CreateModal({
        //     title: "Update Available",
        //     icon: <FiInfo />,
        //     message: "New version of the App is available, the page will reload.",
        //     callback: OnModalConfirmed
        // });

        return (
            <BuildBar>
                <div className="opacity-70 text-warning font-semibold flex flex-row gap-1 items-center"><FiAlertTriangle /> outdated build {props.localBuild} != {response.data.trim()}, please refresh</div>
            </BuildBar>
        )
    }

    return (
        <BuildBar>
            <div className="opacity-50">build {props.localBuild}</div>
        </BuildBar>
    );
}