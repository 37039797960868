import { Spinner } from "@nextui-org/spinner";
import { FiRefreshCw } from "react-icons/fi";

// interface MiniSpinnerProps {
//     size
// }

export function OverlaySpinner(props: any) {
    return (
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-content1 bg-opacity-50 flex items-center justify-center z-50">
            <Spinner />
        </div>
    )
}

export function PageLoadSpinner(props: any) {
    return (
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-content1 bg-opacity-20 flex items-center justify-center z-50">
            <Spinner />
        </div>
    )
}

export function MiniSpinner(props: any) {
    return (
        <FiRefreshCw className="minispinner" />
    )
}