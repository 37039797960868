import React, { useState, useEffect, useRef } from "react";

export function useInterval(callback: Function, delay: number, dependency: any) {
    const savedCallback = useRef();

    // run once on start
    useEffect(() => {
        callback();
    }, [dependency]);

    // Remember the latest callback.
    useEffect(() => {
        // @ts-ignore
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            // @ts-ignore
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
